import { includes, isDate, isEmpty, isString } from "lodash";
import moment from "moment";
import { REGEX } from "../../../../constants";
import { Content, ContentType, Link, VideoProvider, Panels } from "../../../../interfaces";
import { validateInteractions } from "./validateInteractions";

interface ContentValues extends Content {
	prePlayDuration: number;
	bible: {
		enabled: boolean;
		passage: {
			language: string;
			book: string;
			chapter: string;
			translation: string;
		};
	};
	videoHasError: boolean;
}

const decodeBase64 = (str: string) => {
	try {
		let uriDecodedStr = decodeURIComponent(str);
		return window.atob(uriDecodedStr);
	} catch (e) {
		console.log(e);
	}
};

export const validate = (values: ContentValues) => {
	let errors: any = {};

	// name
	if (isEmpty(values.name)) {
		errors.name = "Name is required";
	}

	// description
	if (isEmpty(values.description)) {
		errors.description = "Description is required";
	}

	if (isEmpty(values.channelIds)) {
		errors.channelIds = "Select at least one channel";
	}

	//startsAt
	if (!values.startsAt && values.contentType !== ContentType.onDemand) {
		errors.startsAt = "Start date and time is required";
	}

	if (!values.originalAirDate && values.contentType === ContentType.onDemand) {
		errors.originalAirDate = "Original air date is required";
	}

	if (values.startsAt && isDate(values.startsAt)) {
		if (values.contentType === ContentType.live) {
			if (!values.duration) {
				errors.duration = "Stream duration is required";
			}
			if (values.prePlayDuration === null || values.prePlayDuration < 0) {
				errors.prePlayDuration = "Stream pre-play duration is required";
			}
		}
		if (values.contentType === ContentType.onDemand) {
			if (!values.duration) {
				errors.duration = "Video duration is required";
			}
		}
		if (values.contentType === ContentType.simulatedLive) {
			if (!values.duration) {
				errors.duration = "Video duration is required";
			}

			if (values.prePlayDuration === null || values.prePlayDuration < 0) {
				errors.prePlayDuration = "Video pre-play duration is required";
			}
		}
	}

	// posterImage
	if (isEmpty(values.thumbImage)) {
		errors.thumbImage = "Thumbnail Image is required";
	}

	// contentType
	if (!values.contentType) {
		errors.contentType = "Content Type is required";
	}
	// videoProvider
	if (values.videoProvider !== 0 && !values.videoProvider) {
		errors.videoProvider = "Video Provider is required";
	} else if (!includes(VideoProvider, values.videoProvider)) {
		errors.videoProvider = "Unknown Video Provider";
	}

	// src
	let tempValues: any = { ...values };

	if (isEmpty(values.src)) {
		if (
			values.contentType !== ContentType.live &&
			(tempValues.videoProvider === VideoProvider.youtube ||
				tempValues.videoProvider === VideoProvider.vimeo ||
				tempValues.videoProvider === VideoProvider.facebook)
		) {
			errors.src = "Video URL is required";
		} else if (
			tempValues.videoProvider === VideoProvider.livingAsOne ||
			tempValues.videoProvider === VideoProvider.livingAsOneSingle
		) {
			errors.src = "Embed Id is required";
		} else {
			errors.src = "Embed Code is required";
		}
	} else {
		if (tempValues.videoProvider === VideoProvider.livingAsOne) {
			if (!values.src.match(REGEX.GUID)) {
				errors.src = "Embed Id is not valid";
			}
		} else if (tempValues.videoProvider === VideoProvider.livingAsOneSingle) {
			if (!values.src.match(REGEX.GUID)) {
				const decodedSrcIsValid = decodeBase64(values.src)
					?.split(":")
					.every(guid => guid.match(REGEX.GUID));
				if (!decodedSrcIsValid) errors.src = "Embed Id is not valid";
			}
		} else if (
			tempValues.videoProvider === VideoProvider.livestream ||
			tempValues.videoProvider === VideoProvider.other ||
			values.contentType === ContentType.live
		) {
			if (values.src.startsWith("<iframe")) {
				let iframeUrlMatch = values.src.match("<iframe.+?src=[\"'](.+?)[\"'].*?>");
				if (!iframeUrlMatch) {
					errors.src = "Streaming Embed Code is formatted incorrectly";
				}
			} else {
				errors.src = "Embed Code needs to be a valid iFrame snippet";
			}
		} else if (!values.src.match(REGEX.URL)) {
			errors.src = "Video URL needs to be a valid URL (ex. https://example.com)";
		} else if (tempValues.videoHasError === true) {
			errors.src = "Video source is invalid.";
		}
	}

	// reactions
	if (values.reactions.enabled) {
		const { reactions } = values;
		const numberOfReactions = Object.values(reactions.icons).filter(r => r).length;
		if (numberOfReactions > 4) {
			errors.reactions = "Please select 4 or fewer reactions to include";
		}
	}

	// chat
	if (values.chat.enabled) {
		const { chat } = values;
		if (!isEmpty(chat.startsAt) && !isDate(chat.startsAt)) {
			errors.chat.startsAt = "Chat Starts At is not a valid date";
		}
		if (!isEmpty(chat.endsAt) && !isDate(chat.endsAt)) {
			errors.chat.endsAt = "Chat Starts At is not a valid date";
		}
	}

	// bible
	if (values.bible.enabled) {
		const {
			bible: { passage },
		} = values;
		if (!isString(passage.language)) {
			errors.bible.passage.language = "Invalid Language";
		}
		if (!isString(passage.book)) {
			errors.bible.passage.book = "Invalid Book";
		}
		if (!isString(passage.chapter)) {
			errors.bible.passage.chapter = "Invalid Chapter";
		}
		if (!isString(passage.translation)) {
			errors.bible.passage.translation = "Invalid Translation";
		}
	}

	// notes
	if (values.notes.enabled) {
		const { notes } = values;
		if (!isString(notes.defaultContent)) {
			errors.notes.defaultContent = "Invalid Default Content";
		}
	}

	// links
	if (!isEmpty(values.links)) {
		values.links.forEach((link: Link, index: number) => {
			const linkError: any = {};
			if (isEmpty(link.text)) {
				linkError.text = `(Link ${index + 1}) Link Name is required`;
			}
			if (isEmpty(link.icon)) {
				linkError.icon = `(Link ${index + 1}) Icon is required`;
			}
			if (link.url) {
				const linkUrlError: any = {};
				if (isEmpty(link.url.url)) {
					linkUrlError.url = `(Link ${index + 1}) External Link is required`;
				} else if (!link.url.url.match(REGEX.URL)) {
					linkUrlError.url = `(Link ${index +
						1}) External Link Url must be a valid URL (ex. https://example.com or mailto:example@mail.com)`;
				}
				if (isEmpty(link.url.target)) {
					linkUrlError.target = `(Link ${index + 1}) Target is required`;
				} else if (!includes(["newTab", "sameTab", "iframeModal", "sideBar"], link.url.target)) {
					linkUrlError.target = `(Link ${index + 1}) Unknown URL target`;
				}

				if (!isEmpty(linkUrlError)) {
					linkError.url = linkUrlError;
				}
			}
			if (link.hasOwnProperty("codeProvider")) {
				if (isEmpty(link.codeProvider)) {
					linkError.codeProvider = `(Link ${index + 1}) A code provider must be chosen`;
				}
				//@ts-ignore
				if (isEmpty(link.code) && link.codeProvider !== "chat") {
					linkError.code = `(Link ${index + 1}) A code provider key must be provided`;
				}
			}
			if (link.hasOwnProperty("html")) {
				if (isEmpty(link.html)) {
					linkError.html = `$(Link ${index + 1}) HTML content is required`;
				}
			}

			if (isEmpty(errors.links)) {
				errors.links = [];
			}
			errors.links.push(linkError);
		});

		const anyLinkErrors = errors.links.some((l: any) => !isEmpty(l));
		if (!anyLinkErrors) {
			delete errors.links;
		}
	}

	// socialLinks
	if (!isEmpty(values.socialLinks)) {
		values.socialLinks.forEach((socialLink: any, index: number) => {
			const socialLinkError: any = {};
			if (isEmpty(socialLink.type) || socialLink.type === 0) {
				socialLinkError.type = `(Social Link ${index + 1}) Social Link Type is required`;
			} else if (
				!includes(["facebook", "x", "instagram", "linkedin", "pinterest", "web"], socialLink.type)
			) {
				socialLinkError.type = `(Social Link ${index + 1}) Unknown Social Link type`;
			}
			if (isEmpty(socialLink.url)) {
				socialLinkError.url = `(Social Link ${index + 1}) Social Link Url is required`;
			} else if (!socialLink.url.match(REGEX.URL)) {
				socialLinkError.url = `(Social Link ${index +
					1}) Social Link Url must be a valid URL (ex. https://example.com or mailto:example@mail.com)`;
			}

			if (isEmpty(errors.socialLinks)) {
				errors.socialLinks = [];
			}
			errors.socialLinks.push(socialLinkError);
		});

		const anySocialLinkErrors = errors.socialLinks.some((l: any) => !isEmpty(l));
		if (!anySocialLinkErrors) {
			delete errors.socialLinks;
		}
	}

	if (!isEmpty(values.interactions) && !isEmpty(validateInteractions(values).interactions)) {
		errors.interactions = validateInteractions(values).interactions;
	}

	if (values.convertToOnDemand?.enabled) {
		if (!values.convertToOnDemand.name) {
			errors.convertToOnDemand = { name: "Name is required" };
		}
	}

	// showAt & hideAt
	if (values.showAt && values.startsAt && !!values.prePlayDuration) {
		let opensAt = moment(values.startsAt)
			.subtract(parseInt(values.prePlayDuration.toString()) - 1, "minutes")
			.toDate();
		let showAt = new Date(values.showAt);

		if (showAt >= opensAt) {
			errors.showAt = "Please use a date that is earlier or the same as when this content opens";
		}
	}

	if (values.startsAt && values.duration && values.hideAt) {
		let closesAt =
			values.startsAt && values.duration
				? moment(values.startsAt)
					.add(parseInt(values.duration.toString()) - 1, "minutes")
					.toDate()
				: null;
		let hideAt = new Date(values.hideAt);

		if (closesAt && hideAt <= closesAt) {
			errors.hideAt = "Please use a date that is later or the same as when this content ends";
		}
	}

	if (values.showAt && values.hideAt) {
		if (moment(values.showAt).isAfter(values.hideAt)) {
			errors.showAt = "The After date can not be later than the Until date";
		}
	}

	if (values.initialSelectedPanel || values.initialSelectedPanel === 0) {
		if (values.initialSelectedPanel === Panels.bible && !values.bible.enabled) {
			errors.initialPanel = "The Bible panel must be enabled if it has been selected as the default panel.";
		} else if (values.initialSelectedPanel === Panels.notes && !values.notes.enabled) {
			errors.initialPanel = "The Notes panel must be enabled if it has been selected as the default panel.";
		} else if (values.initialSelectedPanel === Panels.schedule && !values.schedule.enabled) {
			errors.initialPanel = "The Schedule panel must be enabled if it has been selected as the default panel.";
		} else if (values.initialSelectedPanel === Panels.chat && !values.chat.enabled) {
			errors.initialPanel = "The Chat panel must be enabled if it has been selected as the default panel.";
		}
	}

	return errors;
};
