import React, { useContext } from "react";
import { FieldArray, FormikContextType, FieldArrayRenderProps } from "formik";
import { Row, Col } from "react-bootstrap";

import { LinkSectionHeader, SocialLinkSectionHeader } from "./Header";
import { CodeProvider } from "./CodeProvider";
import { Well } from "../../";
import Form from "../../../../../components/Form";
import Editor from "../../../../../components/Form/Editor";
import IconPicker from "../../../../../components/Form/Input/IconPicker";
import { Link, SocialLink } from "../../../../../interfaces";

import * as Styled from "./LinksSection.styled";

interface FormikContext {
	formikContext: FormikContextType<any>;
}

interface ContextInterface {
	name: string;
	disabled: boolean;
	formikContext: any;
}

interface CardProps {
	arrayHelpers: FieldArrayRenderProps;
	index: number;
	length: number;
}

interface LinkCardProps extends CardProps {
	link: Link;
	isContentForm?: boolean;
}

interface SocialLinkCardProps extends CardProps {
	socialLink: SocialLink;
}

export interface LinkCardWrapperProps extends FormikContext {
	name: string;
	disabled?: boolean;
	isContentForm?: boolean;
	learnMoreLink?: string;
	wellMessage?: string;
}

const Context = React.createContext<ContextInterface>({
	name: "",
	disabled: false,
	formikContext: {},
});

const SocialLinkCard: React.FC<SocialLinkCardProps> = ({ arrayHelpers, index, socialLink, length }) => {
	const { name, formikContext } = useContext(Context);
	const { errors, touched } = formikContext;
	const indexLength = length - 1;

	const socialLinkTypeData = [
		{ value: "facebook", label: "Facebook" },
		{ value: "x", label: "X" },
		{ value: "instagram", label: "Instagram" },
		{ value: "linkedin", label: "LinkedIn" },
		{ value: "pinterest", label: "Pinterest" },
		{ value: "web", label: "Web" },
	];

	return (
		<Styled.CustomWell>
			<Styled.CardContents>
				<Form.Field
					component={Form.Input.Select}
					label="Type *"
					id={`${name}[${index}].type`}
					name={`${name}[${index}].type`}
					data={socialLinkTypeData}
					isSearchable={false}
				/>
				<Form.Field label="Url *" id={`${name}[${index}].url`} name={`${name}[${index}].url`} />
			</Styled.CardContents>
			<Styled.ActionsContainer>
				{index > 0 && (
					<Styled.ActionButton type="button" onClick={() => arrayHelpers.move(index, index - 1)}>
						<i className="fal fa-arrow-up" />
					</Styled.ActionButton>
				)}
				{index < indexLength && (
					<Styled.ActionButton type="button" onClick={() => arrayHelpers.move(index, index + 1)}>
						<i className="fal fa-arrow-down" />
					</Styled.ActionButton>
				)}
				<Styled.ActionButton type="button" onClick={() => arrayHelpers.insert(index, socialLink)}>
					<i className="fal fa-copy" />
				</Styled.ActionButton>
				<Styled.ActionButton type="button" onClick={() => arrayHelpers.remove(index)}>
					<i className="fal fa-trash" />
				</Styled.ActionButton>
			</Styled.ActionsContainer>
		</Styled.CustomWell>
	);
};

const LinkCard: React.FC<LinkCardProps> = ({ arrayHelpers, index, link, length, isContentForm = false }) => {
	const { name, disabled, formikContext } = useContext(Context);
	const { errors, touched } = formikContext;
	const indexLength = length - 1;

	const givingProviderLabels: { [key: string]: string } = {
		planningCenterGiving: "ChurchCenter Domain",
		tithely: "Church ID",
		onlineGiving: "Church Code",
		paperlessTransactions: "Subdomain",
		givelify: "URL",
	};

	return (
		<Styled.CustomWell>
			<Styled.CardContents>
				<Row>
					<Col sm={6} className="p-0-sm">
						<Form.Field label="Link Name *" id={`${name}[${index}].text`} name={`${name}[${index}].text`} />
					</Col>
					<Col sm={6} className="p-0-sm">
						<Form.Field
							component={IconPicker}
							label=""
							id={`${name}[${index}].icon`}
							name={`${name}[${index}].icon`}
						/>
					</Col>
				</Row>
				{link.hasOwnProperty("url") && (
					<>
						{link.url?.url === "chat" ? (
							<></>
						) : (
							<>
								<Form.Field
									label="External Link *"
									placeholder="Please enter a URL"
									id={`${name}[${index}].url.url`}
									name={`${name}[${index}].url.url`}
								/>
								<Form.Field
									component={Form.Input.Select}
									label="Target *"
									id={`${name}[${index}].url.target`}
									name={`${name}[${index}].url.target`}
									data={[
										{ value: "newTab", label: "Open in a new tab" },
										{ value: "sameTab", label: "Open in the same tab" },
										{ value: "iframeModal", label: "Open in a modal" },
										{ value: "sideBar", label: "Open in the side bar" },
									]}
									isSearchable={false}
								/>
							</>
						)}
					</>
				)}
				{link.hasOwnProperty("html") && (
					<Form.Field
						component={Editor}
						label="HTML Content *"
						darkMode={true}
						hasImageMenu
						hasHtmlMenu
						id={`${name}[${index}].html`}
						name={`${name}[${index}].html`}
						className="darkmode-field"
						initialValue={link.html}
						disabled={disabled}
						onEditorChange={(content: string) => {
							formikContext.setFieldValue(`${name}[${index}].html`, content);
						}}
					/>
				)}
				{link.hasOwnProperty("code") && (
					<CodeProvider
						name={`${name}[${index}]`}
						codeProvider={link.codeProvider}
						isContentForm={isContentForm}
					/>
				)}
				<Form.Field
					component={Form.Input.Checkbox}
					label="Enable this link?"
					id={`${name}[${index}].enabled`}
					name={`${name}[${index}].enabled`}
				/>
			</Styled.CardContents>
			<Styled.ActionsContainer>
				{index > 0 && (
					<Styled.ActionButton type="button" onClick={() => arrayHelpers.move(index, index - 1)}>
						<i className="fal fa-arrow-up" />
					</Styled.ActionButton>
				)}
				{index < indexLength && (
					<Styled.ActionButton type="button" onClick={() => arrayHelpers.move(index, index + 1)}>
						<i className="fal fa-arrow-down" />
					</Styled.ActionButton>
				)}
				<Styled.ActionButton type="button" onClick={() => arrayHelpers.insert(index + 1, link)}>
					<i className="fal fa-copy" />
				</Styled.ActionButton>
				<Styled.ActionButton type="button" onClick={() => arrayHelpers.remove(index)}>
					<i className="fal fa-trash" />
				</Styled.ActionButton>
			</Styled.ActionsContainer>
		</Styled.CustomWell>
	);
};

const LinksSection: React.FC<LinkCardWrapperProps> = ({
	name,
	disabled = false,
	formikContext,
	isContentForm = false,
	wellMessage,
	learnMoreLink,
}) => {
	return (
		<Context.Provider value={{ name, disabled, formikContext }}>
			<FieldArray
				name={name}
				render={arrayHelpers => {
					return (
						<>
							{name === "links" && (
								<>
									<LinkSectionHeader
										length={formikContext.values.links.length}
										isContentForm={isContentForm}
										addUrlClick={() =>
											arrayHelpers.push({
												text: "",
												icon: "",
												linkType: "url",
												url: { url: "", target: "newTab" },
												enabled: true,
											})
										}
										addHtmlClick={() =>
											arrayHelpers.push({
												text: "",
												icon: "",
												linkType: "html",
												html: "",
												enabled: true,
											})
										}
										addCodeClick={() =>
											arrayHelpers.push({
												text: "",
												icon: "",
												linkType: "code",
												codeProvider: "",
												code: "",
												enabled: true,
											})
										}
									/>
									<Well>
										<p>
											{wellMessage}{" "}
											{learnMoreLink && (
												<span>
													<a href={learnMoreLink} target="_blank" rel="noopener noreferrer">
														Learn more <i className="fal fa-long-arrow-right" />
													</a>
												</span>
											)}
										</p>
									</Well>
								</>
							)}
							{name === "socialLinks" && (
								<>
									<SocialLinkSectionHeader
										addSocialLinkClick={() => arrayHelpers.push({ type: 0, url: "" })}
									/>
									<Well>
										<p>
											{wellMessage}{" "}
											{learnMoreLink && (
												<span>
													<a href={learnMoreLink} target="_blank" rel="noopener noreferrer">
														Learn more <i className="fal fa-long-arrow-right" />
													</a>
												</span>
											)}
										</p>
									</Well>
								</>
							)}
							{formikContext.values[name].map((item: any, index: number) => {
								if (name === "links") {
									return (
										<LinkCard
											key={index}
											arrayHelpers={arrayHelpers}
											link={item}
											index={index}
											length={formikContext.values[name].length}
											isContentForm={isContentForm}
										/>
									);
								} else if (name === "socialLinks") {
									return (
										<SocialLinkCard
											key={index}
											arrayHelpers={arrayHelpers}
											socialLink={item}
											index={index}
											length={formikContext.values[name].length}
										/>
									);
								}
							})}
						</>
					);
				}}
			/>
		</Context.Provider>
	);
};

export default LinksSection;
