import firebase from "firebase";
import { isEmpty } from "lodash";
import moment from "moment-timezone";
import React, { createRef, FC, useEffect, useState } from "react";
import { Button as BSButton, Modal as BSModal } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import styled, { withTheme } from "styled-components";
import { v4 as uuidv4 } from "uuid";
import Bible from "../../components/Bible";
import Button from "../../components/Button";
import { IconLink } from "../../components/Buttons/IconLink";
import firebaseApp from "../../firebase";
import { usePrevious } from "../../hooks";
import {
	ChannelData,
	ChatRequest,
	ChatRequestStatus,
	Client,
	Content,
	ContentType,
	Interaction,
	CustomPanel,
	Panels,
	Response,
	SocialLinkType,
	Theme,
} from "../../interfaces";
import { TypedUseSelectorHook } from "../../redux";
import { hideModalAlert } from "../../redux/Chat/actions";
import { logEvent } from "../../services/analytics";
import { createChatRequest } from "../../services/dataHelpers";
import isLightOrDark from "../../utils/isLightOrDark";
import { timeZoneAbbreviation } from "../../utils/timezones";
import AnimateBubbles from "../AnimateBubbles";
import Chat from "../Chat";
import { Message } from "../Chat/Chat.service";
import { style as chatStyle } from "../Chat/ChatConversation.styled";
import ContactChatAvatar from "../Chat/ContentChatAvatar";
import { style } from "../Chat/styles";
import HeaderImage from "../HeaderImage";
import InteractionResponse from "../InteractionResponse";
import Links from "../Links";
import MobilePanelBar from "../MobilePanels";
import Modal from "../Modals";
import Notes from "../Notes";
import Schedule from "../Schedule";
import { useToast } from "../Toast/ToastProvider";
import { VideoContainer } from "../Video/VideoContainer";
import { VideoList } from "../Video/VideoList/VideoList";
import * as Styled from "./ContentView.styled";

const db = firebaseApp.firestore();

interface ContentViewProps {
	contentData: Content;
	channelData: ChannelData;
	customPanel?: CustomPanel;
	client?: Client;
	contentHappeningNow: Content[];
	contentHappeningNowButNotStarted: Content[];
	contentScheduledForFuture: Content[];
	contentHasHappened: Content[];
	contentOnDemand: Content[];
	upcomingContents: Content[];
	relatedContents: Content[];
	currentTime: string;
	onVideoEnd: () => void;
	theme: Theme;
	contentId?: string;
	powerByLink: string;
	clientKey?: string;
	channelKey?: string;
	contentKey?: string;
	showBumper?: boolean;
	showPlaceholder?: boolean;
	contentValidUntil?: string;
}

const sortContent = (a: Content, b: Content) => {
	let aMoment = new Date("2020-3-15");
	let bMoment = new Date("2020-3-15");
	if (a.contentType === ContentType.onDemand) {
		if (!!a.originalAirDate) {
			aMoment = a.originalAirDate;
		}
	} else {
		if (!!a.startsAt) {
			aMoment = a.startsAt;
		}
	}

	if (b.contentType === ContentType.onDemand) {
		if (!!b.originalAirDate) {
			bMoment = b.originalAirDate;
		}
	} else {
		if (!!b.startsAt) {
			bMoment = b.startsAt;
		}
	}

	const diff = moment(bMoment).diff(moment(aMoment));
	return diff === 0
		? a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()
			? 1
			: b.name.toLocaleLowerCase() > a.name.toLocaleLowerCase()
				? -1
				: 0
		: diff;
};

export const PanelContainer = styled.div`
	background-color: ${({ theme }) => theme.wellBackgroundColor};
	color: ${({ theme }) => theme.wellTextColor};
`;

export const ChannelThumbImageContainer = styled.div<{ image?: string }>`
	overflow: hidden;
	height: 0;
	padding-top: 56.25%;
	${({ image }) => (image ? `background: url('${image}');` : "")}
	background-size: cover;
`;

const VideoListContainer = styled.div`
	display: none;
	@media screen and (max-width: 992px) {
		display: block;
	}
`;

const getSocialLinkTitle = (type: string, url: string) => {
	switch (type) {
		case "facebook":
			return "Facebook";
		case "instagram":
			return "Instagram";
		case "x":
			return "X";
		case "linkedin":
			return "LinkedIn";
		case "pinterest":
			return "Pinterest";
		case "web":
			return url
				.replace("https://", "")
				.replace("http://", "")
				.replace("https://", "www.")
				.split("/")[0];
		default:
			return type;
	}
};

const useTypedSelector: TypedUseSelectorHook = useSelector;

interface MessageCounts {
	[chatId: string]: {
		count: number;
		lastId: string | null;
	};
}

const ContentView: FC<ContentViewProps> = ({
	theme,
	contentData,
	channelData,
	customPanel,
	clientKey,
	channelKey,
	contentKey,
	contentHappeningNow,
	contentHappeningNowButNotStarted,
	contentScheduledForFuture,
	contentHasHappened,
	upcomingContents,
	relatedContents,
	currentTime,
	onVideoEnd,
	contentId,
	client,
	showBumper,
	showPlaceholder,
	contentValidUntil,
}) => {
	const analytics = firebaseApp.analytics();

	const [mountedTime, setMountedTime] = useState<number>(moment.now);
	const [showPanel, setShowPanel] = useState(true);
	const [directChats, setDirectChats] = useState<ChatRequest[] | null>(null);
	const [messageCounts, setMessageCounts] = useState<MessageCounts>({});
	const [messagesChanged, setMessagesChanged] = useState(false);
	const [chatBlacklist, setChatBlacklist] = useState<string[]>();
	const [nextInteraction, setNextInteraction] = useState<Interaction>();
	const [nonintrusiveInteraction, setNonIntrusiveInteraction] = useState(false);
	const [answeredInteractions, setAnsweredInteractions] = useState<string[]>([]);
	const [showPublicChat, setShowPublicChat] = useState(contentData.opensAt ? false : true);
	const [chatClosed, setChatClosed] = useState(false);
	const [currentDirectChat, setCurrentDirectChat] = useState<string>();
	const [nextCurrentDirectChat, setNextCurrentDirectChat] = useState<string>();
	const [switchToDirectChat, setSwitchToDirectChat] = useState(0);
	const [directChatsLoaded, setDirectChatsLoaded] = useState(false);
	const [responseUrlModal, setResponseUrlModal] = useState({ visible: false, src: "", title: "", icon: "" });
	const [showChatIsClosedModal, setShowChatIsClosedModal] = useState(false);
	const [muted, setMuted] = useState(true);
	const [timeLoaded, setTimeLoaded] = useState(moment.utc());
	const { addToast } = useToast();
	const isMobileAndTablet = useMediaQuery({ query: "(max-width: 992px)" });

	const dispatch = useDispatch();

	const isLive =
		contentData.startsAt && contentData.closesAt
			? moment.utc().isBetween(contentData.startsAt, contentData.closesAt, undefined, "[]")
			: false;

	const isOpen =
		contentData.opensAt && contentData.closesAt
			? moment.utc().isBetween(contentData.opensAt, contentData.closesAt, undefined, "[]")
			: false;

	const showChannelThumbnail =
		!contentKey &&
		isEmpty(contentHappeningNow) &&
		isEmpty(contentHappeningNowButNotStarted) &&
		isEmpty(contentHasHappened) &&
		isEmpty(contentScheduledForFuture);

	const showVideoList = relatedContents.length > 0 || upcomingContents.length > 0;

	const setInitialSelectedPanelLegacy: () => Panels = () => {
		if (contentData?.chat.enabled && !!contentData?.startsAt) {
			return Panels.chat;
		} else if (contentData?.bible.enabled) {
			return Panels.bible;
		} else if (contentData?.notes.enabled) {
			return Panels.notes;
		} else if (showVideoList && isMobile) {
			return Panels.videos;
		} else {
			return Panels.info;
		}
	};

	let initialSelectedPanel = contentData?.initialSelectedPanel
		? contentData.initialSelectedPanel
		: setInitialSelectedPanelLegacy();

	const [selectedPanel, setSelectedPanel] = useState<Panels>(initialSelectedPanel);

	const user = useTypedSelector(store => store.user);
	const userStatus = useTypedSelector(store => store.chat.userStatus);
	const { analyticsUserId } = useTypedSelector(store => store.analytics);
	const { showModal, modalData, messages: contentMessages } = useTypedSelector(store => store.chat);

	const now = moment.utc();

	// React to changes in chat if that's the currently selected panel
	useEffect(() => {
		if (selectedPanel === Panels.chat && !contentData.chat.enabled) {
			setSelectedPanel(contentData.initialSelectedPanel ?? setInitialSelectedPanelLegacy());
		}
	}, [contentData.chat.enabled, contentData.initialSelectedPanel, selectedPanel]);

	useEffect(() => {
		if (now.isSameOrAfter(moment.utc(contentData.closesAt))) {
			setSelectedPanel(initialSelectedPanel);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLive]);

	useEffect(() => {
		if (!nextInteraction && selectedPanel === Panels.interactions) {
			setSelectedPanel(initialSelectedPanel);
		}
	}, [initialSelectedPanel, nextInteraction]);

	useEffect(() => {
		if (customPanel?.shouldOpen) {
			setSelectedPanel(Panels.custom);
			customPanel.resetShouldOpen();
		}
	}, [customPanel?.shouldOpen]);

	useEffect(() => {
		let showTimeout: null | ReturnType<typeof setTimeout> = null;
		let closeTimeout: null | ReturnType<typeof setTimeout> = null;
		if (contentData.opensAt) {
			const now = moment.utc();
			const opens = moment.utc(contentData.opensAt);
			const closes = moment.utc(contentData.closesAt);
			if (now.isSameOrAfter(opens)) {
				if (now.isBefore(closes)) {
					setShowPublicChat(true);
					const timeout = closes.diff(now, "milliseconds");
					closeTimeout = setTimeout(
						() => {
							setChatClosed(true);
						},
						timeout <= 43200000 ? timeout + 3000 : 43200000
					);
				} else {
					setChatClosed(true);
					setShowPublicChat(true);
				}
			} else {
				setShowPublicChat(false);
				const timeout = opens.diff(now, "milliseconds");
				showTimeout = setTimeout(
					() => {
						setShowPublicChat(true);
					},
					timeout <= 43200000 ? timeout : 43200000
				);
				const closesTimeout = closes.diff(now, "milliseconds");
				closeTimeout = setTimeout(
					() => {
						setChatClosed(true);
					},
					closesTimeout <= 43200000 ? closesTimeout + 3000 : 43200000
				);
			}
		} else {
			setShowPublicChat(true);
		}
		return () => {
			if (showTimeout) {
				clearTimeout(showTimeout);
			}
			if (closeTimeout) {
				clearTimeout(closeTimeout);
			}
		};
	}, [contentData.opensAt, contentData.closesAt, contentData.showAt, contentData.hideAt]);

	const togglePanel = () => {
		if (showPanel) {
			logEvent("close_panel");
		} else {
			logEvent("open_panel");
		}
		setShowPanel(!showPanel);
	};

	const openPanel = () => {
		if (!showPanel) {
			logEvent("open_panel");
			setShowPanel(true);
		}
	};

	const renderSocialLinks = () => {
		return contentData.socialLinks.map((socialLink, index) => {
			return (
				<IconLink
					key={`${socialLink.type}-${index + 1}`}
					url={socialLink.url}
					icon={SocialLinkType[socialLink.type] === "web" ? "globe" : SocialLinkType[socialLink.type]}
					weight={SocialLinkType[socialLink.type] === "web" ? "far" : "fab"}
					onClick={() => logEvent("click_content_link", socialLink.url)}
				/>
			);
		});
	};

	const selectExistingHostChat = () => {
		const hostChat = directChats?.find(x => x.hostChat);
		if (hostChat) {
			setCurrentDirectChat(hostChat.id);
			setSelectedPanel(Panels.directChat);
			setSwitchToDirectChat(v => {
				return v + 1;
			});
			setTimeout(() => {
				setNextInteraction(undefined);
			}, 500);
		}
	};

	useEffect(() => {
		analytics.setCurrentScreen(`/${clientKey}/${channelKey}/${contentData.key}`);
		logEvent("view_content");
	}, [analytics, channelKey, clientKey, contentData.key]);

	useEffect(() => {
		if (contentId && isOpen && (!user.isLoadingAppUser || user.frontEndUser)) {
			const unsubscribe = db
				.collection("interactions")
				.doc("contents")
				.collection(contentId)
				.where("versionCode", "==", null)
				.onSnapshot(
					async querySnapshot => {
						let allInteractions: Interaction[] = [];
						querySnapshot.forEach(q => {
							allInteractions.push({ ...(q.data() as Interaction) });
						});

						let unansweredInteractions: Interaction[] = [];

						for (const interaction of allInteractions) {
							try {
								const answersSnapshot = await db
									.collection("answers")
									.doc("interactions")
									.collection(interaction.id)
									.get();
								if (answersSnapshot.size > 0) {
									let allAnswersForThisInteraction: any[] = [];
									answersSnapshot.forEach(a => allAnswersForThisInteraction.push({ ...a.data() }));
									if (
										!allAnswersForThisInteraction.some(a => {
											if (a.firebaseId === analyticsUserId) {
												return true;
											} else {
												return false;
											}
										})
									) {
										unansweredInteractions.push(interaction);
									}
								} else {
									unansweredInteractions.push(interaction);
								}
							} catch (error) {
								console.warn("Error getting existing answers", error);
							}
						}
						unansweredInteractions.sort((a, b) => {
							return (new Date(b.showAt as string) as any) - (new Date(a.showAt as string) as any);
						});
						allInteractions.sort((a, b) => {
							return (new Date(b.showAt as string) as any) - (new Date(a.showAt as string) as any);
						});

						let interactionsThatHaveShowAt = allInteractions.filter(i => {
							if (!i.showAt) {
								return false;
							} else {
								return timeLoaded.isSameOrBefore(moment.utc(i.showAt));
							}
						});

						let currentInteraction;
						// we will only show a interaction if:
						// - the user has not answered the interaction
						// - we have a interaction that has a `showAt` time
						if (!isEmpty(unansweredInteractions) && !isEmpty(interactionsThatHaveShowAt)) {
							// both of these arrays are sorted the same.
							// only show the interaction if
							// - the next interaction that has `showAt` is the same as the next unanswered interaction
							//
							// if this does NOT happen, that means the interaction has been answered.
							//
							// right now (4/20/20) we are only showing one interaction. we aren't queuing interactions
							// up to the user. meaning - if 2 interactions come up that have not been answered by
							// the user yet, then the user can only answer the most recent of those. the older
							// interaction does not show to the user.
							if (unansweredInteractions[0].id === interactionsThatHaveShowAt[0].id) {
								currentInteraction = unansweredInteractions[0];
							}
						}

						setNextInteraction(currentInteraction);

						if (currentInteraction?.urgency === "notify") {
							setNonIntrusiveInteraction(true);
						} else if (currentInteraction?.urgency === "focus") {
							setSelectedPanel(Panels.interactions);
							setShowPanel(true);
						} else if (currentInteraction?.urgency === "modal") {
							try {
								if (document.fullscreen) {
									document.exitFullscreen();
								}
							} catch (error) { }
						}
					},
					error => {
						console.log(`Encountered error on interactions subscribe: ${error}`);
					}
				);

			return () => unsubscribe();
		}
	}, [analyticsUserId, contentId, user.isLoadingAppUser, user.frontEndUser, isOpen]);

	const responseToInteraction = async (
		interaction: Interaction,
		response: Response | null,
		data: any | null,
		showThanks = false
	) => {
		if (!showThanks) {
			const newAnswer = {
				id: uuidv4(),
				contentId,
				firebaseId: analyticsUserId,
				interactionId: interaction.id,
				interactionTitle: interaction.interactionTitle,
				interactionText: interaction.interactionText,
				showAt: interaction.showAt ? new Date(interaction.showAt).toISOString() : null,
				hideAt: interaction.hideAt ? new Date(interaction.hideAt).toISOString() : null,
				responseId: response ? response.id : null,
				responseText: response ? response.responseText : null,
				action: response ? response.actionType : "dismiss",
				answeredAt: moment.utc().toISOString(),
				data,
			};

			if (!data) {
				delete newAnswer.data;
			}

			// console.log(`/answers/interactions/${interaction.id}/${newAnswer.id}`);

			try {
				await db
					.collection("answers")
					.doc("interactions")
					.collection(interaction.id)
					.doc(newAnswer.id)
					.set(newAnswer);
			} catch (error) {
				console.warn("Error responding to interaction", error);
			}

			const newAnsweredInteractions = [...answeredInteractions];
			newAnsweredInteractions.push(interaction.id);
			setAnsweredInteractions(newAnsweredInteractions);
		}

		if (response && response.actionType !== "dismiss") {
			if (response.actionType === "startChat") {
				const result = await createChatRequest(
					contentId!,
					data.uid,
					data.displayName || "Anonymous",
					user.frontEndUser?.displayName
						? `${user.frontEndUser?.displayName ?? ""} responded ${response.responseText} to ${interaction.interactionTitle
						}`
						: `Responded ${response.responseText} to ${interaction.interactionTitle}`, //`Responded to ${response.responseText}`,
					user.frontEndUser?.photoURL || null,
					ChatRequestStatus.request,
					true
				);
				if (result === false) {
					selectExistingHostChat();
				} else {
					setSelectedPanel(Panels.directChat);
					setSwitchToDirectChat(v => {
						return v + 1;
					});
					setNextInteraction(undefined);
				}
			} else if (response.actionType === "url") {
				const { url } = response;

				if (url.target === "newTab") {
					//Opened via a tag
				} else if (url.target === "sameTab") {
					window.open(url?.url, "_self");
				} else {
					setResponseUrlModal({
						visible: true,
						src: url.url,
						title: "",
						icon: "",
					});
				}
				setSelectedPanel(initialSelectedPanel);
				setNextInteraction(undefined);
			} else if (response.actionType === "collectEmail" || response.actionType === "close") {
				if (isEmpty(response.actionText)) {
					setSelectedPanel(initialSelectedPanel);
					setNextInteraction(undefined);
				}
			}
		} else if (!response || response.actionType === "dismiss") {
			setSelectedPanel(initialSelectedPanel);
			setNextInteraction(undefined);
		}
	};

	useEffect(() => {
		if (!currentDirectChat || nextCurrentDirectChat !== currentDirectChat) {
			setCurrentDirectChat(nextCurrentDirectChat);
		}
	}, [nextCurrentDirectChat]);

	useEffect(() => {
		let videoListActive = showChannelThumbnail && !showBumper;
		if (!videoListActive) {
			document.body.classList.add("lock-scroll");
		}
		return () => {
			document.body.classList.remove("lock-scroll");
		};
	}, [showChannelThumbnail, showBumper]);

	useEffect(() => {
		let unsubscribe = () => { };
		if (user.frontEndUser?.uid) {
			const myUid = user.frontEndUser?.uid;
			setDirectChatsLoaded(false);
			unsubscribe = db
				.collection("chat")
				.doc("direct")
				.collection(contentData.id)
				.where("users", "array-contains", user.frontEndUser?.uid)
				.onSnapshot(snapshot => {
					let currentChats: ChatRequest[] = [];
					let newChatBlacklist: string[] = [];

					snapshot.forEach(doc => {
						const data = doc.data() as ChatRequest;
						const myStatus = data.userData[myUid].status;
						if (
							myStatus !== undefined &&
							myStatus !== ChatRequestStatus.ended &&
							myStatus !== ChatRequestStatus.directMessageEnded &&
							myStatus !== ChatRequestStatus.blocked &&
							(data.createdBy === user.frontEndUser?.uid || data.showRequest)
						) {
							currentChats.push(data);
						}
						if (
							[
								// ChatRequestStatus.request,
								// ChatRequestStatus.active,
								// ChatRequestStatus.directMessageRequest,
								// ChatRequestStatus.directMessageActive,
								ChatRequestStatus.blocked,
							].includes(myStatus) ||
							data.status === ChatRequestStatus.blocked
						) {
							newChatBlacklist.push(...data.users);
						}
					});
					currentChats = currentChats.sort((a, b) => {
						return (b.updatedAt ?? 0) - (a.updatedAt ?? 0);
					});
					if (currentChats.length === 0) {
						setSelectedPanel(initialSelectedPanel);
						setNextCurrentDirectChat(undefined);
					}
					setDirectChats(currentChats);
					setChatBlacklist(newChatBlacklist);
				});
		}
		return () => {
			unsubscribe();
		};
	}, [contentData.id, initialSelectedPanel, user.frontEndUser, switchToDirectChat]);

	const previousDirectChats: ChatRequest[] | null = usePrevious(directChats) as any;

	useEffect(() => {
		if ((directChats?.length ?? 0) > (previousDirectChats?.length ?? 0)) {
			if (directChatsLoaded) {
				setSelectedPanel(Panels.directChat);
				setShowPanel(true);
			} else {
				setDirectChatsLoaded(true);
			}
			if (directChats) {
				setNextCurrentDirectChat(directChats[0].id);

				const previousChatIds = previousDirectChats?.map(c => c.id) || [];
				const newDirectChats = directChats.filter(chat => !previousChatIds.includes(chat.id));

				if (newDirectChats.some(chat => chat.hosts.length > 0)) {
					setSelectedPanel(Panels.directChat);
					setShowPanel(true);
				}
			}
		} else if ((directChats?.length ?? 0) < (previousDirectChats?.length ?? 0)) {
			if (
				directChats &&
				directChats.length > 0 &&
				directChats.find(x => x.id === currentDirectChat) === undefined
			) {
				setNextCurrentDirectChat(directChats[0].id);
			}
		}
	}, [directChats]);

	const onChatClickWhenChatIsClosed = () => {
		setShowChatIsClosedModal(true);
	};

	const onChatClick = () => {
		if (directChats?.length) {
			setSelectedPanel(Panels.directChat);
			setShowPanel(true);
		}
	};

	const setCurrentChat = (chatRequest: ChatRequest) => {
		setSelectedPanel(Panels.directChat);
		setShowPanel(true);
		setCurrentDirectChat(chatRequest.id);
	};

	const closeInteractionModal = (interaction: Interaction, hasAnswered: boolean, showThanks: boolean) => {
		responseToInteraction(interaction, null, null, hasAnswered && showThanks);
	};

	const closeResponseUrlModal = () => {
		setResponseUrlModal({
			visible: false,
			src: "",
			title: "",
			icon: "",
		});
	};

	useEffect(() => {
		if (currentDirectChat && messagesChanged && selectedPanel === Panels.directChat) {
			setMessagesChanged(false);
			let newMessageCounts = Object.assign({}, messageCounts);
			if (user.frontEndUser) {
				directChats?.forEach(directChat => {
					if (directChat.userData[user.frontEndUser?.uid || ""]?.muted && newMessageCounts[directChat.id]) {
						newMessageCounts[directChat.id].count = 0;
					}
				});
			}
			if (currentDirectChat && newMessageCounts[currentDirectChat]) {
				newMessageCounts[currentDirectChat].count = 0;
				setMessageCounts(newMessageCounts);
			}
		}
		if (selectedPanel === Panels.chat && messagesChanged) {
			setMessagesChanged(false);
			let newMessageCounts = Object.assign({}, messageCounts);

			if (user.frontEndUser) {
				directChats?.forEach(directChat => {
					if (directChat.userData[user.frontEndUser?.uid || ""]?.muted && newMessageCounts[directChat.id]) {
						newMessageCounts[directChat.id].count = 0;
					}
				});
			}

			if (contentId && newMessageCounts[contentId]) {
				newMessageCounts[contentId].count = 0;
				setMessageCounts(newMessageCounts);
			}
		}
	}, [messageCounts, selectedPanel]);

	const onMessages = (chatId: string, messages: Message[]) => {
		let newMessageCounts = Object.assign({}, messageCounts);
		let found = newMessageCounts[chatId]?.lastId ? false : true;
		let count = 0;
		let latestMoment = moment(new Date(1970, 0, 1));
		if (chatId === contentId && contentData.useChatProcessor) {
			const lastMessage = contentMessages[newMessageCounts[chatId]?.lastId ?? ""];
			let time = mountedTime;
			if (lastMessage) {
				time = moment(lastMessage.timestamp.toDate()).valueOf();
			}
			messages.forEach(message => {
				if (message.timestamp instanceof firebase.firestore.Timestamp) {
					if (moment(message.timestamp.toDate()).valueOf() > time) {
						count++;
					}
				}
			});
		} else {
			messages.forEach(message => {
				if (found && message.timestamp > mountedTime) {
					count++;
				}

				if (!found && newMessageCounts[chatId]?.lastId === message.id) {
					found = true;
				}
			});
		}

		if (!newMessageCounts[chatId]) {
			newMessageCounts[chatId] = {
				count: 0,
				lastId: null,
			};
		}
		newMessageCounts[chatId].count += count;
		if (messages.length > 0 && count > 0) {
			newMessageCounts[chatId].lastId = messages[messages.length - 1].id;
		}
		setMessagesChanged(true);
		setMessageCounts(newMessageCounts);
	};

	const selectCurrentChat = (id: string) => {
		if (id !== "none") {
			const unreadMessages = { ...messageCounts };
			unreadMessages[id].count = 0;
			setMessageCounts(unreadMessages);
		}
		setCurrentDirectChat(id);
	};

	const selectMainChat = () => {
		if (contentId && messageCounts[contentId]) {
			const unreadMessages = { ...messageCounts };
			unreadMessages[contentId].count = 0;
			setMessageCounts(unreadMessages);
		}
	};

	const panelBackgroundColor = theme.wellBackgroundColor;
	const panelTextColor = theme.wellTextColor;

	let videoWidth = "calc(100% - 60px)";
	if (showPanel) {
		videoWidth = "calc(100% - 406px)";
	}

	const isSidebarInteraction = nextInteraction && ["notify", "focus"].includes(nextInteraction.urgency);

	return (
		<div>
			<div>
				<div>
					<HeaderImage src={channelData?.headerImage} />
				</div>

				<div
					className={`video-container  ${!showChannelThumbnail && !showBumper && upcomingContents.length > 0
							? ""
							: "remove-video-container"
						}${!isEmpty(contentData.links) ? " video-with-links" : ""} ${showBumper ? "bumper-video-container" : ""
						}`}
				>
					{showChannelThumbnail && !showBumper ? (
						<>
							{upcomingContents.length > 0 && channelData.thumbImage && (
								<ChannelThumbImageContainer image={channelData.thumbImage} />
							)}
						</>
					) : showBumper ? (
						<Styled.BumperVideoContainer>
							<Styled.BumperVideoWrapper>
								{muted && (
									<button className="unmute-button" onClick={() => setMuted(false)}>
										<i className="fas fa-volume-up"></i> Unmute
									</button>
								)}
								<ReactPlayer
									className="player"
									url={channelData.defaultVideo?.src}
									playsinline
									width="100%"
									height="100%"
									playing={true}
									loop={true}
									muted={muted}
									controls={true}
									config={{
										youtube: {
											playerVars: {
												disablekb: 1,
												modestbranding: 1,
											},
										},
									}}
								/>
							</Styled.BumperVideoWrapper>
						</Styled.BumperVideoContainer>
					) : (
						!showPlaceholder && (
							<>
								<div className="video" style={{ width: videoWidth }}>
									<VideoContainer
										contentData={contentData}
										channelData={channelData}
										customPanel={customPanel}
										currentTime={currentTime}
										isRightPanelExpanded={showPanel}
										onVideoEnd={onVideoEnd}
										onChatClickWhenChatIsClosed={onChatClickWhenChatIsClosed}
										onChatClick={onChatClick}
										selectExistingHostChat={selectExistingHostChat}
										contentValidUntil={contentValidUntil}
									/>
								</div>
								<PanelContainer
									className={`video-panel`}
									style={{ width: showPanel ? "396px" : "50px" }}
								>
									{!isMobileAndTablet ? (
										<div
											className="video-panel-sidebar"
											style={{ backgroundColor: theme.buttonBackgroundColor }}
										>
											<div className="panel-button-container">
												<Button
													id="toggle-video-panel"
													className="panel-button"
													style={{
														color: theme.buttonTextColor,
													}}
													onClick={togglePanel}
													backgroundColor={theme.buttonBackgroundColor}
													hoverBackgroundColor={theme.buttonHoverBackgroundColor}
												>
													<i
														className={`fal fa-${showPanel ? "arrow-from-left" : "arrow-to-left"
															}`}
														style={{ fontSize: 28 }}
													/>
												</Button>
												{contentData?.chat.enabled && !!contentData?.startsAt && (
													<Button
														id="toggle-chat"
														className={`panel-button${Object.keys(messageCounts).some(
															x => x === contentId && messageCounts[x].count > 0
														)
																? " has-activity"
																: ""
															}`}
														onClick={() => {
															logEvent("open_chat");
															selectMainChat();
															openPanel();
															setSelectedPanel(Panels.chat);
														}}
														backgroundColor={
															selectedPanel === Panels.chat
																? panelBackgroundColor
																: theme.buttonBackgroundColor
														}
														color={
															selectedPanel === Panels.chat
																? panelTextColor
																: theme.buttonTextColor
														}
														hoverBackgroundColor={theme.buttonHoverBackgroundColor}
														hoverColor={theme.buttonTextColor}
													>
														<i
															className={`fal fa-comment-lines`}
															style={{ fontSize: 28 }}
														/>
													</Button>
												)}
												{(directChats?.length || 0 > 0) && (
													<Button
														id="toggle-direct-chat"
														className={`panel-button${Object.keys(messageCounts).some(
															x => x !== contentId && messageCounts[x].count > 0
														)
																? " has-activity"
																: ""
															}`}
														onClick={() => {
															logEvent("open_direct_chat");
															openPanel();
															setMessagesChanged(true);
															setSelectedPanel(Panels.directChat);
														}}
														backgroundColor={
															selectedPanel === Panels.directChat
																? panelBackgroundColor
																: theme.buttonBackgroundColor
														}
														color={
															selectedPanel === Panels.directChat
																? panelTextColor
																: theme.buttonTextColor
														}
														hoverBackgroundColor={theme.buttonHoverBackgroundColor}
														hoverColor={theme.buttonTextColor}
													>
														<i className={`fal fa-comments`} style={{ fontSize: 28 }} />
													</Button>
												)}
												{contentData?.notes.enabled && (
													<Button
														id="toggle-notes"
														className="panel-button"
														onClick={() => {
															logEvent("open_notes");
															openPanel();
															setSelectedPanel(Panels.notes);
														}}
														backgroundColor={
															selectedPanel === Panels.notes
																? panelBackgroundColor
																: theme.buttonBackgroundColor
														}
														color={
															selectedPanel === Panels.notes
																? panelTextColor
																: theme.buttonTextColor
														}
														hoverBackgroundColor={theme.buttonHoverBackgroundColor}
														hoverColor={theme.buttonTextColor}
													>
														<i className={`fal fa-sticky-note`} style={{ fontSize: 28 }} />
													</Button>
												)}
												{contentData?.schedule?.enabled && (
													<Button
														id="toggle-schedule"
														className="panel-button"
														onClick={() => {
															logEvent("open_schedule");
															openPanel();
															setSelectedPanel(Panels.schedule);
														}}
														backgroundColor={
															selectedPanel === Panels.schedule
																? panelBackgroundColor
																: theme.buttonBackgroundColor
														}
														color={
															selectedPanel === Panels.schedule
																? panelTextColor
																: theme.buttonTextColor
														}
														hoverBackgroundColor={theme.buttonHoverBackgroundColor}
														hoverColor={theme.buttonTextColor}
													>
														<i className={`fal fa-calendar-alt`} style={{ fontSize: 28 }} />
													</Button>
												)}
												{contentData?.bible.enabled && (
													<Button
														id="toggle-bible"
														className="panel-button"
														onClick={() => {
															logEvent("open_bible");
															openPanel();
															setSelectedPanel(Panels.bible);
														}}
														backgroundColor={
															selectedPanel === Panels.bible
																? panelBackgroundColor
																: theme.buttonBackgroundColor
														}
														color={
															selectedPanel === Panels.bible
																? panelTextColor
																: theme.buttonTextColor
														}
														hoverBackgroundColor={theme.buttonHoverBackgroundColor}
														hoverColor={theme.buttonTextColor}
													>
														<i className={`fal fa-bible`} style={{ fontSize: 28 }} />
													</Button>
												)}
												{nextInteraction && isSidebarInteraction && isOpen && (
													<Button
														id="toggle-interactions"
														className={`panel-button ${nonintrusiveInteraction ? " has-activity" : ""
															}`}
														onClick={() => {
															logEvent("open_questions");
															setSelectedPanel(Panels.interactions);
															openPanel();
															setNonIntrusiveInteraction(false);
														}}
														backgroundColor={
															selectedPanel === Panels.interactions
																? panelBackgroundColor
																: theme.buttonBackgroundColor
														}
														color={
															selectedPanel === Panels.interactions
																? panelTextColor
																: theme.buttonTextColor
														}
														hoverBackgroundColor={theme.buttonHoverBackgroundColor}
														hoverColor={theme.buttonTextColor}
													>
														<i
															className={`fal fa-comment-alt-exclamation`}
															style={{ fontSize: 28 }}
														/>
													</Button>
												)}
												{showVideoList && (
													<Button
														id="toggle-videos"
														className="panel-button"
														onClick={() => setSelectedPanel(Panels.videos)}
														backgroundColor={
															selectedPanel === Panels.videos
																? panelBackgroundColor
																: theme.buttonBackgroundColor
														}
														color={
															selectedPanel === Panels.videos
																? panelTextColor
																: theme.buttonTextColor
														}
														hoverBackgroundColor={theme.buttonHoverBackgroundColor}
														hoverColor={theme.buttonTextColor}
													>
														<i className={`fal fa-film`} style={{ fontSize: 28 }} />
													</Button>
												)}
												{customPanel?.panel && (
													<Button
														id="toggle-info"
														className="panel-button"
														onClick={() => {
															openPanel();
															setSelectedPanel(Panels.custom);
														}}
														backgroundColor={
															selectedPanel === Panels.custom
																? panelBackgroundColor
																: theme.buttonBackgroundColor
														}
														color={
															selectedPanel === Panels.custom
																? panelTextColor
																: theme.buttonTextColor
														}
														hoverBackgroundColor={theme.buttonHoverBackgroundColor}
														hoverColor={theme.buttonTextColor}
														style={{ float: "right" }}
													>
														<i
															className={`fal ${customPanel.panel.icon}`}
															style={{ fontSize: 28 }}
														/>
													</Button>
												)}
											</div>
											<Button
												id="toggle-info"
												className="panel-button"
												onClick={() => {
													openPanel();
													setSelectedPanel(Panels.info);
												}}
												backgroundColor={
													selectedPanel === Panels.info
														? panelBackgroundColor
														: theme.buttonBackgroundColor
												}
												color={
													selectedPanel === Panels.info
														? panelTextColor
														: theme.buttonTextColor
												}
												hoverBackgroundColor={theme.buttonHoverBackgroundColor}
												hoverColor={theme.buttonTextColor}
												style={{ float: "right" }}
											>
												<i className={`fal fa-info-circle`} style={{ fontSize: 28 }} />
											</Button>
										</div>
									) : (
										<MobilePanelBar
											content={contentData}
											setSelectedPanel={setSelectedPanel}
											selectedPanel={selectedPanel}
											customPanel={customPanel}
											openPanel={openPanel}
											isLive={isLive}
											showVideoList={showVideoList}
											newInteraction={!!(nextInteraction && isSidebarInteraction)}
											directChats={!!directChats?.length ?? false}
											selectExistingHostChat={selectExistingHostChat}
											newDirectChats={Object.keys(messageCounts).some(
												x => x !== contentId && messageCounts[x].count > 0
											)}
											newChats={Object.keys(messageCounts).some(
												x => x === contentId && messageCounts[x].count > 0
											)}
										/>
									)}
									{showPanel && (
										<div className="video-panel-content">
											{!!contentData.startsAt && (
												<style.ChatContainer
													id="chat-container"
													className={`chat-container overflow-hidden ${selectedPanel === Panels.chat ? "" : "hidden"
														}`}
												>
													{user.isLoggingOut ? (
														<div className="absolute-center ta-center">
															<i className="fal fa-spinner-third fa-spin fa-2x m-bottom"></i>
															<br />
															Leaving Chat
														</div>
													) : (
														<>
															{contentData &&
																contentData.id &&
																!user.frontEndIsLoading &&
																(user.frontEndUser?.uid || user.isLoggingOut) && (
																	<>
																		{showPublicChat ? (
																			<Chat
																				clientId={contentData.clientId}
																				id={contentData?.id}
																				isModerator={false}
																				enableAnonymous={
																					contentData.chat.enableAnonymous ||
																					false
																				}
																				isLightBackground={
																					isLightOrDark(
																						theme.wellBackgroundColor
																					) === "light"
																				}
																				chatClosed={chatClosed}
																				isLoggingOut={user.isLoggingOut}
																				contentId={contentData.id}
																				content={contentData}
																				client={client}
																				useChatProcessor={
																					contentData.useChatProcessor
																				}
																				canUseChatProcessor
																				enablePreview={
																					contentData.chat.enablePreview ??
																					false
																				}
																				chatBlacklist={chatBlacklist}
																				onMessages={onMessages}
																				setCurrentChat={setCurrentChat}
																			/>
																		) : (
																			<div
																				style={{
																					width: "100%",
																					height: "100%",
																					display: "flex",
																					flex: 1,
																					alignItems: "center",
																					justifyContent: "center",
																					textAlign: "center",
																					fontSize: 20,
																				}}
																			>
																				<div className="ta-center">
																					<i className="fal fa-clock"></i>
																					<br />
																					<h3>Chat opens soon</h3>
																				</div>
																			</div>
																		)}
																	</>
																)}
														</>
													)}
												</style.ChatContainer>
											)}

											{directChats && (
												<>
													{contentData && contentData.id && !user.isLoading && (
														<>
															{selectedPanel === Panels.directChat && (
																<chatStyle.ChatAvatarTabs
																	numberOfChats={directChats.length}
																	className="direct-chat-tabs"
																>
																	<AnimateBubbles>
																		{directChats.map(directChat => {
																			const startedByMe =
																				directChat.createdBy ===
																				user.frontEndUser?.uid;
																			const initials = (startedByMe
																				? directChat.recipientUserName || ""
																				: directChat.userName || ""
																			)
																				.split(" ")
																				.slice(0, 2)
																				.map((word: any) =>
																					word.substring(0, 1).toUpperCase()
																				);
																			const image =
																				directChat.userData &&
																				directChat.userData[
																					Object.keys(
																						directChat.userData
																					).filter(
																						u =>
																							u !== user.frontEndUser?.uid
																					)[0]
																				]?.image;
																			return (
																				<chatStyle.ChatAvatarTab
																					key={directChat.id}
																					ref={createRef()}
																					className={`direct-chat-tab${currentDirectChat ===
																							directChat.id
																							? " active"
																							: ""
																						}${messageCounts[directChat.id]
																							?.count > 0
																							? " unreadChats"
																							: ""
																						}`}
																					onClick={() =>
																						selectCurrentChat(directChat.id)
																					}
																				>
																					<ContactChatAvatar
																						initials={initials}
																						image={image}
																						userName={
																							startedByMe
																								? directChat.recipientUserName
																								: directChat.userName
																						}
																						uid={
																							startedByMe
																								? Object.keys(
																									directChat.userData
																								).filter(
																									u =>
																										u !==
																										user
																											.frontEndUser
																											?.uid
																								)[0]
																								: directChat.createdBy
																						}
																						contentId={contentId}
																						request={directChat}
																					/>
																				</chatStyle.ChatAvatarTab>
																			);
																		})}
																	</AnimateBubbles>
																</chatStyle.ChatAvatarTabs>
															)}

															{directChats.map(directChat => (
																<style.ChatContainer
																	key={directChat.id}
																	id="chat-container"
																	className={`chat-container overflow-hidden ${directChats.length > 0 ? "has-chat-picker" : ""
																		} ${selectedPanel === Panels.directChat &&
																			currentDirectChat === directChat.id
																			? ""
																			: "hidden"
																		}`}
																>
																	<Chat
																		clientId={contentData.clientId}
																		id={directChat.id}
																		isModerator={false}
																		enableAnonymous={
																			contentData.chat.enableAnonymous || false
																		}
																		isDirect
																		request={directChat}
																		contentId={contentData.id}
																		isLoggingOut={user.isLoggingOut}
																		isLightBackground={
																			isLightOrDark(theme.wellBackgroundColor) ===
																			"light"
																		}
																		content={contentData}
																		client={client}
																		enablePreview={
																			contentData.chat.enablePreview ?? false
																		}
																		onMessages={onMessages}
																		userStatus={userStatus}
																	/>
																</style.ChatContainer>
															))}
														</>
													)}
												</>
											)}

											{selectedPanel === Panels.notes && (
												<div id="notes-container">
													<Notes
														content={contentData.notes.defaultContent}
														contentId={contentData.id}
													/>
												</div>
											)}
											{selectedPanel === Panels.schedule && (
												<div id="schedule-container" className="h-100">
													<Schedule
														scheduledContent={upcomingContents.filter(con =>
															con.channelIds.includes(channelData.id)
														)}
														channelKey={channelKey}
														clientKey={clientKey}
													/>
												</div>
											)}
											{selectedPanel === Panels.videos && (
												<div id="video-list-container" style={{ padding: 15 }}>
													<VideoList
														clientKey={clientKey}
														channelKey={channelKey}
														onVideoEnd={onVideoEnd}
														upcomingContents={upcomingContents}
														relatedContents={relatedContents}
														contentKey={contentData.key}
														showRelatedTitle={upcomingContents.length > 0}
														theme={theme}
													/>
												</div>
											)}
											{selectedPanel === Panels.info && (
												<Styled.InfoPanel>
													<Styled.InfoHeader>
														<Styled.InfoTitle>{contentData.name}</Styled.InfoTitle>
														{contentData.startsAt && (
															<Styled.InfoTime>
																{moment(contentData.startsAt).format(
																	"dddd, MMMM D, YYYY h:mm A"
																)}{" "}
																(
																{timeZoneAbbreviation(
																	moment(contentData.startsAt).toISOString()
																)}
																)
															</Styled.InfoTime>
														)}
														<Styled.InfoValidUntil>
															{contentValidUntil}
														</Styled.InfoValidUntil>
													</Styled.InfoHeader>
													<Styled.InfoDescription
														dangerouslySetInnerHTML={{
															__html: contentData.description || "",
														}}
													/>
													<Styled.IconWrapper>{renderSocialLinks()}</Styled.IconWrapper>
												</Styled.InfoPanel>
											)}
											{selectedPanel === Panels.bible && (
												<div id="bible-container" style={{ height: "100%" }}>
													<Bible
														url={contentData.bible.url}
														showBible={selectedPanel === Panels.bible}
													/>
												</div>
											)}
											{customPanel?.panel && (
												<Styled.CustomPanel visible={selectedPanel === Panels.custom}>
													{customPanel.panel.content}
												</Styled.CustomPanel>
											)}
											{selectedPanel === Panels.interactions && nextInteraction && isOpen && (
												<div
													id="interactions-container"
													style={{
														height: "100%",
														padding: 15,
														backgroundColor: theme.wellBackgroundColor,
													}}
												>
													<InteractionResponse
														interaction={nextInteraction}
														respondToInteraction={responseToInteraction}
														user={user}
													/>
												</div>
											)}
										</div>
									)}
									<div style={{ clear: "both" }} />
								</PanelContainer>
								{!isMobileAndTablet && !isEmpty(contentData.links) && (
									<Links
										mobile
										content={contentData}
										customPanel={customPanel}
										onChatClickWhenChatIsClosed={onChatClickWhenChatIsClosed}
										onChatClick={onChatClick}
										selectExistingHostChat={selectExistingHostChat}
									/>
								)}
							</>
						)
					)}
				</div>
			</div>

			{nextInteraction && nextInteraction.urgency === "modal" && (
				<InteractionResponse
					interaction={nextInteraction}
					respondToInteraction={responseToInteraction}
					user={user}
					isModal
					modalVisible={!!nextInteraction}
					closeModal={closeInteractionModal}
				/>
			)}
			{showChatIsClosedModal && (
				<BSModal
					show={showChatIsClosedModal}
					onHide={() => setShowChatIsClosedModal(false)}
					className="default-modal"
				>
					<BSModal.Header closeButton>
						<BSModal.Title>Chat is currently closed</BSModal.Title>
					</BSModal.Header>
					<BSModal.Body>
						<p>Chat is currently unavailable.</p>
					</BSModal.Body>

					<BSModal.Footer>
						<BSButton
							className="modal-button cancel-button"
							style={{ width: "100%" }}
							variant="secondary"
							onClick={() => setShowChatIsClosedModal(false)}
						>
							Close
						</BSButton>
					</BSModal.Footer>
				</BSModal>
			)}
			{!!modalData && !!showModal && (
				<BSModal show={showModal} onHide={() => dispatch(hideModalAlert(false))} className="default-modal">
					<BSModal.Header closeButton>
						<BSModal.Title>{modalData.title}</BSModal.Title>
					</BSModal.Header>
					<BSModal.Body>
						<p>{modalData.message}</p>
					</BSModal.Body>

					<BSModal.Footer>
						<BSButton
							style={{ width: "100%" }}
							variant="primary"
							onClick={() => {
								dispatch(hideModalAlert(false));
							}}
						>
							{modalData.closeButtonText}
						</BSButton>
					</BSModal.Footer>
				</BSModal>
			)}

			<Modal.IFrame
				visible={responseUrlModal.visible}
				closeModal={closeResponseUrlModal}
				src={responseUrlModal.src}
				title={responseUrlModal.title}
				icon={responseUrlModal.icon}
			/>
		</div>
	);
};

export default withTheme(ContentView);
