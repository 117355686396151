export interface SocialLink {
	type: SocialLinkType;
	url: string;
}

export enum SocialLinkType {
	facebook,
	x,
	instagram,
	linkedin,
	pinterest,
	web
}
